<template>
  <el-dialog :close-on-click-modal='false' class="dialog3" :visible.sync="dialogVisiblethree" :width="screenWidth>500?'450px':'344px'">
    <div class="top" slot="title">
      <div>{{$t('poplypop.汇生汇')}} <span>({{$t('poplypop.香港')}}) {{$t('poplypop.文化科技有限公司')}}</span></div>
      <div class="text">({{$t('poplypop.深圳')}}) {{$t('poplypop.文化科技有限公司')}}</div>
      <div class="text">{{$store.state.info.hotline}}</div>
      <div class="text">{{$store.state.info.phone}}</div>
    </div>
    <div class="content3">
      <div>{{$t('poplypop.姓名')}}</div>
      <div style="margin-bottom: 26PX;">
        <el-input v-model="query.name" :placeholder="$t('poplypop.请输入')"></el-input>
      </div>
      <div>{{$t('poplypop.性别')}}</div>
      <div class="sexbtn">
        <div v-for="(item,index) in list1" :key='index' :class="aindex==index?'activeitem':''" @click="toaindex(index)">{{item}}</div>
      </div>
      <div>{{$t('poplypop.留学申请')}}</div>
      <div class="sexbtn">
         <div v-for="(item,index) in list2" :key='index' :class="aindextwo==index?'activeitem':''" @click="toaindextwo(index)">{{item}}</div>
      </div>
      <div>{{$t('poplypop.微信号')}}</div>
      <el-input v-model="query.wxAccount" :placeholder="$t('poplypop.请输入')"></el-input>
    </div>
    <span slot="footer" class="dialog-footer-pop">
      <el-button type="primary" @click="liuxuechange">{{$t('poplypop.提交')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "lianxiwomen",
  data() {
    return {
      dialogVisiblethree: false,
      form: {},
      aindex:0,
      aindextwo:0,
      query:{
        recordType:3,//	必选	String	1:在线留言2:学生公寓3:香港留学
        category:'',//	必选	String	来源位置1:首页学术公寓2:首页香港留学3:(recordType=1)
        name:'',//	必选	String	姓名
        phone:'',//必选	String	手机号
        wxAccount:'',//	必选	String	微信账号
        address:'',//	必选	String	地址
        content:'副学士',//	必选	String	留言内容(recordType=2/3申请内容)
        gender:'男',//	必选	String	性别(recordType=2/3)
      },
      screenWidth: document.body.clientWidth,
    };
  },
  computed:{
    list1(){
      return [this.$t('poplypop.男'),this.$t('poplypop.女')]
    },
    list2(){
      return [this.$t('poplypop.副学士'),this.$t('poplypop.本科'),this.$t('poplypop.研究生')]
    }
  },
  methods: {
    open() {
      
      this.dialogVisiblethree = true;
      
    },
    toaindex(e){
        this.aindex=e
        if(e==0){
          this.query.gender='男'
        }
        if(e==1){
          this.query.gender='女'
        }
    },
    toaindextwo(e){
        this.aindextwo=e 
        if(e==0){
          this.query.content='副学士'
        }
        if(e==1){
          this.query.content='本科'
        }
        if(e==2){
          this.query.content='研究生'
        }
    },
    liuxuechange(){
      // this.query.gender=this.list1[this.aindex]
      console.log(this.query.gender)
      this.$emit('liuxuechange',this.query)
      this.aindex=0
      this.aindextwo=0
      this.query={
        recordType:3,//	必选	String	1:在线留言2:学生公寓3:香港留学
        category:'',//	必选	String	来源位置1:首页学术公寓2:首页香港留学3:(recordType=1)
        name:'',//	必选	String	姓名
        phone:'',//必选	String	手机号
        wxAccount:'',//	必选	String	微信账号
        address:'',//	必选	String	地址
        content:'副学士',//	必选	String	留言内容(recordType=2/3申请内容)
        gender:'男',//	必选	String	性别(recordType=2/3)
      }
      this.dialogVisiblethree = false
    }
  },
};
</script>

<style lang="scss">
.dialog3 {
  .el-button:hover {
    border-color: #fe982bc2;
  }

  .el-button--primary:hover {
    background-color: #fe982bc2;
    border-color: #fe982bc2;
  }

  .el-button--primary {
    color: #fff;
    background-color: #fe992b;
    border-color: #fe992b;
  }

  .el-input__inner:focus {
    border-color: #e3e3e2;
    outline: 0;
  }
   .el-input__inner{
    padding: 20PX 10PX;
    font-size: 12PX;
  }
  .el-textarea__inner{
    padding: 10PX 10PX;
    font-size: 12PX;
  }
  .el-textarea__inner:focus {
    outline: 0;
    border-color: #e3e3e2;
  }

  .el-dialog__body {
    text-align: left;
    font-size: 14PX;
  }

  .el-dialog {
    border-radius: 12PX;
  }

  .el-dialog__header {
    // text-align: left;
    background-color: #fe992b;
    border-radius: 12PX 12PX 0 0;
    padding: 10PX 20PX;
  }
.el-dialog__footer {
    // padding: 10PX;
    text-align: center;
    padding: 0 0 40PX 0;
  }
  .el-dialog__close {
    color: #fff;
    font-size: 16PX;
    margin: 10PX 10PX 0 0;
  }

  .content3 {
    padding: 0 20PX;
    > div {
      margin-bottom: 10PX;
     
    }
    .sexbtn{
        display: flex;
        flex-direction: row;
      flex-wrap: wrap;
        >div{
            border:1PX solid #fe992b;
            color: #fe992b;
            //width: 70PX;
          padding: 0 10PX;
            height: 34PX;
            line-height: 34PX;
            text-align: center;
            border-radius: 4PX;
            margin-right: 25PX;
          margin-bottom: 16PX;
        }
        .activeitem{
            background: #fe992b;
            color: #fff;
        }
    }
    .input2 {
      width: 304PX;
      border-radius: 4PX;
      border: none;
      padding: 15PX;
      background-color: #fff;
      border: 1PX solid #e3e3e2;
      font-size: 16PX;
      outline: none;
    }
  }
     
  .top {
    text-align: left;
    color: #fff;
    font-size: 14PX;

    .text {
      margin-left: 56PX;
    }

    > div {
      span {
        margin-left: 10PX;
      }

      margin-bottom: 4PX;
    }
  }
  .dialog-footer-pop{
    text-align: center;
    width: 100%;
    padding: 0 30PX;
    button{
      line-height: 28PX;
      font-size: 16PX;
      width: calc(100% - 60PX);
      padding: 10PX 0;
    }
  }
}
</style>