<template>
  <el-dialog :close-on-click-modal='false' class="dialog4" :visible.sync="dialogVisiblefour" :width="screenWidth>500?'450px':'344px'">
    <div class="top" slot="title">
      <div>{{$t('poplypoptwo.汇生汇')}} <span>({{$t('poplypoptwo.香港')}}) {{$t('poplypoptwo.文化科技有限公司')}}</span></div>
      <div class="text">({{$t('poplypoptwo.深圳')}}) {{$t('poplypoptwo.文化科技有限公司')}}</div>
      <div class="text">{{$store.state.info.hotline}}</div>
      <div class="text">{{$store.state.info.phone}}</div>
    </div>
    <div class="content3">
      <div>{{$t('poplypoptwo.姓名')}}</div>
      <div style="margin-bottom: 26PX">
        <el-input v-model="query.name" :placeholder="$t('poplypoptwo.请输入')"></el-input>
      </div>
      <div>{{$t('poplypoptwo.性别')}}</div>
      <div class="sexbtn">
         <div v-for="(item,index) in list1" :key='index' :class="aindex==index?'activeitem':''" @click="toaindex(index)">{{item}}</div>
      </div>
      <div>{{$t('poplypoptwo.准备就读院校')}}</div>
      <div>
         <el-select v-model="content" :placeholder="$t('poplypoptwo.请选择')" style="width: 100%;margin-bottom: 26PX">
            <el-option
              v-for="item in addresslist"
              :key="item.name"
              :label="item.name"
              :value="item.name" style="font-size:14PX;line-height:30PX;height:30PX">
            </el-option>
         </el-select>
      </div>
      <el-input v-model="query.content" :placeholder="$t('poplypoptwo.请输入目前')" v-if="content==$t('poplypoptwo.其他')"></el-input>
      <div>{{$t('poplypoptwo.微信号')}}</div>
      <el-input v-model="query.wxAccount" :placeholder="$t('poplypoptwo.请输入')"></el-input>
    </div>
    <span slot="footer" class="dialog-footer-pop">
      <el-button type="primary" @click="apartmentClick">{{$t('poplypoptwo.提交')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {getSchool} from '@/api/index.js'
export default {
  name: "applypoptwo",
  data() {
    return {
      dialogVisiblefour: false,
      form: {},
      aindex:0,
      options:[
        {label:'香港大學1',value:'1'},
        {label:'香港大學2',value:'2'},
        {label:'香港大學3',value:'3'},
        {label:'其他',value:'0'},
      ],
      value: '',
      query:{
        recordType:2,//	必选	String	1:在线留言2:学生公寓3:香港留学
        category:'',//	必选	String	来源位置1:首页学术公寓2:首页香港留学3:(recordType=1)
        name:'',//	必选	String	姓名
        phone:'',//	必选	String	手机号
        wxAccount:'',//	必选	String	微信账号
        address:'',//	必选	String	地址
        content:'',//	必选	String	留言内容(recordType=2/3申请内容)
        gender:'男',//	必选	String	性别(recordType=2/3)
      },
      addresslist:[],
      content:'',
      screenWidth: document.body.clientWidth,
    };
  },
  computed:{
    list1(){
        return [this.$t('poplypoptwo.男'),this.$t('poplypoptwo.女')]
      },
  },
  created(){
    this._getSchool()
  },
  methods: {
    //提交
    apartmentClick(){
      if(this.content!=this.$t('poplypoptwo.其他')){
        this.query.content=this.content
      }
      console.log(this.query.gender)
      // return
      this.$emit('apartchange',this.query)
      this.aindex=0
      this.query={
        recordType:2,//	必选	String	1:在线留言2:学生公寓3:香港留学
        category:'',//	必选	String	来源位置1:首页学术公寓2:首页香港留学3:(recordType=1)
        name:'',//	必选	String	姓名
        phone:'',//	必选	String	手机号
        wxAccount:'',//	必选	String	微信账号
        address:'',//	必选	String	地址
        content:'',//	必选	String	留言内容(recordType=2/3申请内容)
        gender:'男',//	必选	String	性别(recordType=2/3)
      }
      this.dialogVisiblefour = false
    },
    //获取所有学校
    _getSchool(){
      let obj={
        name:this.$t('poplypoptwo.其他')
      }
      getSchool({
        addressId:''
      }).then(res=>{
        
        this.addresslist=res.data
        this.addresslist.push(obj)
      })
    },
    open() {
      this.dialogVisiblefour = true;
    },
    toaindex(e){
        this.aindex=e
      if(e==0){
        this.query.gender='男'
      }
      if(e==1){
        this.query.gender='女'
      }
    },
  },
};
</script>

<style lang="scss" >
.dialog4 {
  .el-button:hover {
    border-color: #fe982bc2;
  }

  .el-button--primary:hover {
    background-color: #fe982bc2;
    border-color: #fe982bc2;
  }

  .el-button--primary {
    color: #fff;
    background-color: #fe992b;
    border-color: #fe992b;
  }

  .el-input__inner:focus {
    border-color: #e3e3e2;
    outline: 0;
  }
   .el-input__inner{
    padding: 20PX 10PX;
    font-size: 12PX;
  }
  .el-textarea__inner{
    padding: 10PX 10PX;
    font-size: 12PX;
  }
  .el-textarea__inner:focus {
    outline: 0;
    border-color: #e3e3e2;
  }

  .el-dialog__body {
    text-align: left;
    font-size: 14PX;
  }

  .el-dialog {
    border-radius: 12PX;
  }

  .el-dialog__header {
    // text-align: left;
    background: #fe992b!important;
    border-radius: 12PX 12PX 0 0;
    padding: 10PX 20PX;
  }
.el-dialog__footer {
    // padding: 10PX;
    text-align: center;
    padding: 0 0 40PX 0;
  }
  .el-dialog__close {
    color: #fff;
    font-size: 16PX;
    margin: 10PX 10PX 0 0;
  }

  .content3 {
    padding: 0 20PX;
    text-align: left;
    > div {
     margin-bottom: 10px;
    }
    .sexbtn{
        display: flex;
        flex-direction: row;
        >div{
            border:1PX solid #fe992b;
            color: #fe992b;
            width: 70PX;
            height: 34PX;
            line-height: 34PX;
            text-align: center;
            border-radius: 4PX;
            margin-right: 25PX;
          margin-bottom: 16PX;
        }
        .activeitem{
            background: #fe992b;
            color: #fff;
            font-size: 14PX;
        }
    }
    .input2 {
      width: 304PX;
      border-radius: 4PX;
      border: none;
      padding: 15PX;
      background-color: #fff;
      border: 1PX solid #e3e3e2;
      font-size: 16PX;
      outline: none;
    }
  }
     
  .top {
    text-align: left;
    color: #fff;
    font-size: 14PX;
    .text {
      margin-left: 56PX;
    }

    > div {
      span {
        margin-left: 10PX;
      }

      margin-bottom: 4PX;
    }
  }
  .dialog-footer-pop{
    text-align: center;
    width: 100%;
    padding: 0 30PX;
    button{
      line-height: 28PX;
      font-size: 16PX;
      width: calc(100% - 60PX);
      padding: 10PX 0;
    }
  }
}
</style>