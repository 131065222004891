<template>
  <div>
    <div>
      <el-carousel trigger="click" :height="imgHeight" indicator-position="outside">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <img :src="item.imageUrl" alt="" class="banner-image" :style="[{height: imgHeight }]" @click="tobanner(item)">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerList: {
      type: Array,
      default() {
        return [];
      },
    }
  },
  data() {
    return {
      imgHeight: ""
    }
  },
  mounted() {
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener("resize", () => {
      this.swiperHeight();
    });
    this.swiperHeight()
  },
  methods: {
    tobanner(item){
      this.$emit('bannerchange',item)
    },
    swiperHeight() {
      this.$nextTick(() => {
        this.imgHeight = Math.ceil((document.body.clientWidth * 500) / 1920) + 'px';
        if (document.body.clientWidth <= 768) {
          this.imgHeight = '100px'
        }
      });
    },
  }
}
</script>

<style>
.banner-image {
  width: 100%;
  object-fit: cover;
}
</style>
<style scoped>

/deep/ .el-carousel__button {
  width: 36PX;
  height: 8PX;
  border: none;
  border-radius: 20PX;
  background: #999;
  opacity: 1;
}

/* 指示灯位置 */
/deep/ .el-carousel__indicators {
  position: absolute;
  bottom: 0;
  left: calc(50% - 74PX);
  display: none;
}

/deep/ .is-active .el-carousel__button {
  background: #FE992B;
  opacity: 1;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 20PX;
}

::v-deep .el-carousel__indicator--horizontal {
  padding: 12PX 10PX;
}

::v-deep .el-carousel__arrow {
  font-size: 14PX;
  width: 40PX;
  height: 40PX;
}
</style>