<template>
  <div class="page">
    <!-- pc -->
    <div class="top-mian hidden-xs-only">
      <div class="frame">
        <my-carousel :bannerList='bannerArr' @bannerchange='bannerchange'></my-carousel>
       <template v-if="language=='zh'">
         <div class="frameone" @click="toapplypop(2)">
           <img src="../../assets/image/homepage/tk1.png" alt="">
         </div>
         <div class="frametwo" @click="toapplypop(1)">
           <img src="../../assets/image/homepage2.png" alt="">
         </div>
       </template>
        <template v-if="language=='TW'">
          <div class="frameone" @click="toapplypop(2)">
            <img src="../../assets/image/homepage/tk1TW.png" alt="">
          </div>
          <div class="frametwo" @click="toapplypop(1)">
            <img src="../../assets/image/homepage2TW.png" alt="">
          </div>
        </template>
        <template v-if="language=='En'">
          <div class="frameone" @click="toapplypop(2)">
            <img src="../../assets/image/homepage/tk1En.png" alt="">
          </div>
          <div class="frametwo" @click="toapplypop(1)">
            <img src="../../assets/image/homepage2En.png" alt="">
          </div>
        </template>
      </div>
      <div :class="screenWidth>=992?'main-service ':'main-service-sm' " >
        <div class="main-service-top">
          <div class="english-yellow">{{$t('homepage.Serviceselection')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('homepage.服务精选')}}</div>
            <div class="servicebot"></div>
            <div class="all-service">
              <div class="backgimage-service" v-for="(item,index) in servicrList" :key='index' @click="toservice(index)">
                <div>
                  <img :src="item.url" alt="">
                </div>
                <div class="liuxuejihua">
                  <span>{{ item.toptitle }}</span>
                  <div>{{ item.bottomtitle }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-service-bot" style="margin-top: 20PX">
          <div class="english-yellow">{{$t('homepage.Strengthes')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('homepage.HK汇生会')}}</div>
            <div class="servicebot"></div>
            <div class="all-service">
              <template v-if="language=='zh'">
                <div class="backgimage-service-two" v-for="(item,index) in studentList" :key='index'
                     @mouseover="over(index)" @mouseout="out(index)" >
                  <div v-if="item.active==true">
                    <img :src="item.activeurl" alt="">
                  </div>
                  <div v-else>
                    <img :src="item.url" alt="">
                  </div>
                </div>
              </template>
              <template v-if="language=='TW'">
                <div class="backgimage-service-two" v-for="(item,index) in TWstudentList" :key='index'
                     @mouseover="over(index)" @mouseout="out(index)" >
                  <div v-if="item.active==true">
                    <img :src="item.activeurl" alt="">
                  </div>
                  <div v-else>
                    <img :src="item.url" alt="">
                  </div>
                </div>
              </template>
              <template v-if="language=='En'">
                <div class="backgimage-service-two" v-for="(item,index) in EnstudentList" :key='index'
                     @mouseover="over(index)" @mouseout="out(index)" >
                  <div v-if="item.active==true">
                    <img :src="item.activeurl" alt="">
                  </div>
                  <div v-else>
                    <img :src="item.url" alt="">
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="HKmain-service-bot" :style="[{'height':companyheight}]" style="margin-top: 50PX">
          <div class="english-yellow">{{$t('homepage.APPLICATIONPROCESS')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('homepage.支持')}}</div>
            <div class="servicebot"></div>
            <div class="all-service-company" >
              <div v-for="(item,index) in agencList" :key='index'  >
                <img :src="item.thumbnail" alt=""  >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div  class="top-mian hidden-sm-and-up">
      <div class="phone-frame">
        <my-carousel :bannerList='bannerArr'></my-carousel>
        <template v-if="language=='zh'">
          <div class="phone-frameone" @click="toapplypop(2)">
            <img src="../../assets/image/homepage/tk1.png" alt="">
          </div>
          <div class="phone-frametwo" @click="toapplypop(1)">
            <img src="../../assets/image/homepage2.png" alt="">
          </div>
        </template>
        <template v-if="language=='TW'">
          <div class="phone-frameone" @click="toapplypop(2)">
            <img src="../../assets/image/homepage/tk1TW.png" alt="">
          </div>
          <div class="phone-frametwo" @click="toapplypop(1)">
            <img src="../../assets/image/homepage2TW.png" alt="">
          </div>
        </template>
        <template v-if="language=='En'">
          <div class="phone-frameone" @click="toapplypop(2)">
            <img src="../../assets/image/homepage/tk1En.png" alt="">
          </div>
          <div class="phone-frametwo" @click="toapplypop(1)">
            <img src="../../assets/image/homepage2En.png" alt="">
          </div>
        </template>
      </div>
      <div class="main-phone-sm" >
        <div class="heightone">
          <div class="english-yellow-sm">{{$t('homepage.Serviceselection')}}</div>
          <div class="service-con-sm">
            <div class="servicetop-sm">{{$t('homepage.服务精选')}}</div>
            <div class="servicebot-sm"></div>
            <div class="all-service-sm ">
              <template v-if="language=='zh'">
                <div  v-for="(item,index) in servicrListtwo" :key='index' @click="toservice(index)">
                  <div>
                    <img :src="item.url" alt="">
                  </div>
                </div>
              </template>
              <template v-if="language=='TW'">
                <div  v-for="(item,index) in servicrListtwoTW" :key='index' @click="toservice(index)">
                  <div>
                    <img :src="item.url" alt="">
                  </div>
                </div>
              </template>
              <template v-if="language=='En'">
                <div  v-for="(item,index) in servicrListtwoEn" :key='index' @click="toservice(index)">
                  <div>
                    <img :src="item.url" alt="">
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="heighttwo">
          <div class="english-yellow-sm">{{$t('homepage.Strengthes')}}</div>
          <div class="service-con-sm">
            <div class="servicetop-sm">{{$t('homepage.HK汇生会')}}</div>
            <div class="servicebot-sm"></div>
            <div class="all-service-sm ">
              <template v-if="language=='zh'">
                <div class="backgimage-service-two-sm" v-for="(item,index) in studentList" :key='index'
                     @mouseover="over(index)" @mouseout="out(index)" >
                  <div v-if="item.active==true"  >
                    <img :src="item.activeurl" alt=""   >
                  </div>
                  <div v-else  >
                    <img :src="item.url" alt=""    >
                  </div>
                </div>
              </template>
              <template v-if="language=='TW'">
                <div class="backgimage-service-two-sm" v-for="(item,index) in TWstudentList" :key='index'
                     @mouseover="over(index)" @mouseout="out(index)" >
                  <div v-if="item.active==true"  >
                    <img :src="item.activeurl" alt=""   >
                  </div>
                  <div v-else  >
                    <img :src="item.url" alt=""    >
                  </div>
                </div>
              </template>
              <template v-if="language=='En'">
                <div class="backgimage-service-two-sm" v-for="(item,index) in EnstudentList" :key='index'
                     @mouseover="over(index)" @mouseout="out(index)" >
                  <div v-if="item.active==true"  >
                    <img :src="item.activeurl" alt=""   >
                  </div>
                  <div v-else  >
                    <img :src="item.url" alt=""    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
         <div class="main-service-bot-sm" :style="[{'height':companyheight2}]">
          <div class="english-yellow-sm">{{$t('homepage.APPLICATIONPROCESS')}}</div>
          <div class="service-con-sm">
            <div class="servicetop-sm">{{$t('homepage.支持')}}</div>
            <div class="servicebot-sm"></div>
            <div class="all-service-company-sm" >
              <div v-for="(item,index) in agencList" :key='index' >
                <img :src="item.thumbnail" alt=""  >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Myapplypop ref="pop" @liuxuechange='liuxuechange'></Myapplypop>
    <Myapplypoptwo ref="poptwo" @apartchange='apartchange'></Myapplypoptwo>
    <Mydialog ref="icon"  @sumbit='sumbit'></Mydialog>
    <Myicon @iconchange='iconchange'></Myicon>
  </div>
</template>

<script>
import MyCarousel from '@/components/carousel'
import Myapplypop from '@/components/applypop'
import Myapplypoptwo from '@/components/applypoptwo'
import Mydialog from "@/components/dialog"
import Myicon from '@/components/mainicon'
import "element-ui/lib/theme-chalk/display.css";
import {getJianzhiOne,agencList,addMessageRecord} from '@/api/index'
export default {
  components: {
    MyCarousel,
    Myapplypop,
    Myapplypoptwo,
    Myicon,
    Mydialog
  },
  data() {
    return {
      studentimgHeight: '',
      servicrListtwo: [
        { url: require('@/assets/phone/homepage/s1.png')},
        { url: require('@/assets/phone/homepage/s2.png')},
        { url: require('@/assets/phone/homepage/s3.png')},
        { url: require('@/assets/phone/homepage/s4.png')},
        { url: require('@/assets/phone/homepage/s5.png')},
        { url: require('@/assets/phone/homepage/s6.png')}
      ],
      servicrListtwoTW: [
        { url: require('@/assets/phone/homepage/tw/s1.png')},
        { url: require('@/assets/phone/homepage/tw/s2.png')},
        { url: require('@/assets/phone/homepage/tw/s3.png')},
        { url: require('@/assets/phone/homepage/tw/s4.png')},
        { url: require('@/assets/phone/homepage/tw/s5.png')},
        { url: require('@/assets/phone/homepage/tw/s6.png')}
      ],
      servicrListtwoEn: [
        { url: require('@/assets/phone/homepage/En/s1.png')},
        { url: require('@/assets/phone/homepage/En/s2.png')},
        { url: require('@/assets/phone/homepage/En/s3.png')},
        { url: require('@/assets/phone/homepage/En/s4.png')},
        { url: require('@/assets/phone/homepage/En/s5.png')},
        { url: require('@/assets/phone/homepage/En/s6.png')}
      ],
      language:'zh',
      studentList: [
        {
          url: require(`@/assets/image/homepage/zh/x1.png`),
          activeurl: require('@/assets/image/homepage/zh/a1.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/zh/x2.png'),
          activeurl: require('@/assets/image/homepage/zh/a2.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/zh/x3.png'),
          activeurl: require('@/assets/image/homepage/zh/a3.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/zh/x4.png'),
          activeurl: require('@/assets/image/homepage/zh/a4.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/zh/x5.png'),
          activeurl: require('@/assets/image/homepage/zh/a5.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/zh/x6.png'),
          activeurl: require('@/assets/image/homepage/zh/a6.png'),
          active: false
        }
      ],
      TWstudentList:[
        {
          url: require(`@/assets/image/homepage/tw/x1.png`),
          activeurl: require('@/assets/image/homepage/tw/a1.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/tw/x2.png'),
          activeurl: require('@/assets/image/homepage/tw/a2.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/tw/x3.png'),
          activeurl: require('@/assets/image/homepage/tw/a3.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/tw/x4.png'),
          activeurl: require('@/assets/image/homepage/tw/a4.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/tw/x5.png'),
          activeurl: require('@/assets/image/homepage/tw/a5.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/tw/x6.png'),
          activeurl: require('@/assets/image/homepage/tw/a6.png'),
          active: false
        }
      ],
      EnstudentList:[
        {
          url: require(`@/assets/image/homepage/en/x1.png`),
          activeurl: require('@/assets/image/homepage/en/a1.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/en/x2.png'),
          activeurl: require('@/assets/image/homepage/en/a2.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/en/x3.png'),
          activeurl: require('@/assets/image/homepage/en/a3.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/en/x4.png'),
          activeurl: require('@/assets/image/homepage/en/a4.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/en/x5.png'),
          activeurl: require('@/assets/image/homepage/en/a5.png'),
          active: false
        },
        {
          url: require('@/assets/image/homepage/en/x6.png'),
          activeurl: require('@/assets/image/homepage/en/a6.png'),
          active: false
        }
      ],
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      baseUrl:'',
      bannerArr:[],//首页banner
      agencList:[],//合作伙伴list
      
    }
  },
  computed:{
    companyheight(){
      if(this.agencList.length>0){
     return  Math.ceil(this.agencList.length/4)*110+250 +'PX'
      }else{
         return 300+'PX'
      }
    },
     companyheight2(){
      if(this.agencList.length>0){
        return (Math.ceil(this.agencList.length/Math.floor( 335/100))*110+40)+'PX'
      }else{
         return 100+'PX'
      }
    },
    companymargin2(){
       if(this.agencList.length>0){
        return Math.floor((this.screenWidth*0.9-3*224)/2)+'PX'
      }else{
         return 0+'PX'
      }
    },
    companymargin(){
      if(this.agencList.length>0){
        return Math.floor(( this.screenWidth*0.9-Math.floor(this.screenWidth*0.9/224)*224)/(Math.floor(this.screenWidth*0.9/224)-1))+'PX'
      }else{
         return 0+'PX'
      }
    },
    servicrList(){
      return [
        {toptitle: this.$t('homepage.留学申请计划'), bottomtitle: this.$t('homepage.副学士') , url: require('@/assets/image/homepage/s1.png')},
        {toptitle: this.$t('homepage.留学文书创作'), bottomtitle: this.$t('homepage.为您提供有效的学习规划') , url: require('@/assets/image/homepage/s2.png')},
        {toptitle: this.$t('homepage.留学面试辅导'), bottomtitle: this.$t('homepage.帮您逐一攻克面试流程') , url: require('@/assets/image/homepage/s3.png')},
        {toptitle: this.$t('homepage.背景提升室'), bottomtitle:  this.$t('homepage.全方位提高竞争力'), url: require('@/assets/image/homepage/s4.png')},
        {toptitle: this.$t('homepage.名校直通车'), bottomtitle:  this.$t('homepage.学校直录'), url: require('@/assets/image/homepage/s5.png')},
        {toptitle: this.$t('homepage.香港学生公寓'), bottomtitle: this.$t('homepage.给你一个不一样的家') , url: require('@/assets/image/homepage/s6.png')}
      ]
    } 
  },
   created () {
    if( !localStorage.getItem("language")) {
      localStorage.setItem("language", "zh")
    }
  },
  mounted() {

    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      })();
    };
    window.addEventListener("resize", () => {
       that.$nextTick(() => {
        that.$forceUpdate()
      });
    });
    that._getJianzhiOne()
    this._agencList()
    this.language=localStorage.getItem('language')||'zh'
    that.baseUrl=that.$store.state.baseUrl

  },
  created(){
  },
  methods: {
    //香港留学提交
    liuxuechange(e){
      console.log(e)
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('admission.已提交'))
      })
    },
    //学生公寓
    apartchange(e){
       console.log(e)
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('admission.已提交'))
      })
    },
    //在线咨询
    sumbit(e){
        e.category=1
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('admission.已提交'))
      })
    },
    //点击banner，跳转url
    bannerchange(e){
      console.log(e)
      window.location.href=e.jumpUrl
    },
    toservice(index){
      if(index==0||index==2){//香港留学
        this.$router.push({
          name: "OverseasStudy",
        });
      }
      if(index==1){//商城
        this.$router.push({
          name: "",
        });
      }
      if(index==3){//背景提升
        this.$router.push({
          name: "background",
        });
      }
      if(index==4){//名校直通车
        this.$router.push({
          name: "Famous-school",
        });
      }
      if(index==5){//公寓
        this.$router.push({
          path: 'studentApartment',
        });
      }
    },
      _getJianzhiOne(){
        let obj={
          category:1
        }
        getJianzhiOne(obj).then(res=>{
          console.log(res)
          res.data.forEach((item)=>{
            item.imageUrl=this.baseUrl+item.imageUrl
          })
          this.bannerArr=res.data
          console.log(this.bannerArr)
        })
      },
      _agencList(){
        console.log('222')
        agencList().then(res=>{
          console.log(res)
          res.data.forEach((item)=>{
            item.thumbnail=this.baseUrl+item.thumbnail
          })
          this.agencList=res.data
        })
      },
    iconchange(){
      this.$refs.icon.dialogVisible=true
    },
    toapplypop(e){
      if(e==1){
        this.$refs.pop.dialogVisiblethree=true
      }
      if(e==2){
        this.$refs.poptwo.dialogVisiblefour=true
      }
    },
    over(e){
      console.log(e)
      if(this.language=='zh'){
        this.studentList[e].active = true
      }
      if(this.language=='TW'){
        this.TWstudentList[e].active = true
      }
      if(this.language=='En'){
        this.EnstudentList[e].active = true
      }
    },
    out(e){
      console.log(e)
      if(this.language=='zh'){
        this.studentList[e].active = false
      }
      if(this.language=='TW'){
        this.TWstudentList[e].active = false
      }
      if(this.language=='En'){
        this.EnstudentList[e].active = false
      }
    },
    // studentchange(e) {
    //   this.studentList[e].active = !this.studentList[e].active
    // },
  }
}
</script>
<style lang="scss" scoped >
.top-mian {
  width: 100%;
}
.main-phone-sm{
  width: 335PX;
  margin: 0 auto;
}
.main-phone-sm > div {
  width: 100%;
  padding: 70px 0;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-service {
  width:1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
}

.main-service-sm {
  width: 1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
}

.main-service > div, .main-service-sm > div {
  width: 100%;
  padding: 70px 0;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-service-top {
  height: 570px;
}

.main-service-bot {
  height: 650PX;
}

.HKmain-service-bot {
  // height: 806px;
  // min-height: 400PX;
  padding: 30PX 0;
} 

.english-yellow {
  color: #FE992B;
  font-size: 44PX;
  opacity: 0.3;
}

.service-con {
  width: 100%;
  position: absolute;
  top: 80PX;
}

.servicetop {
  font-size: 32PX;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
  border-radius: 3PX;
  margin: 0 auto;
}

.servicebot {
  width: 150PX;
  height: 6PX;
  background: #FE992B;
  border-radius: 3PX;
  margin: 0 auto;
}

.all-service {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20PX;
}
.all-service-company{
  width:1176PX;
  padding: 30PX 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20PX auto 0 auto;
}
.all-service-company>div{
  width: 280PX;
  height: 100PX;
  margin-bottom: 30PX;
  //margin:0 calc(25% - 320PX) 30PX calc(25% - 320PX);
}
.all-service-company>div img{
  width: 280PX!important;
  height: 100PX;
}
.companypng{
  width: 100%;
  height: 400px;
}
.all-service >div{
  width: 30%;
  margin-bottom: 30px;
}

.backgimage-service {
  height: 184px;
  position: relative;
  top: 0;
}

.backgimage-service img {
  width: 369PX;
  height: 184px;
}

.backgimage-service-two img {
  width: 340PX;
  height: 244PX;
}

.HKbackgimage-service-two img {
  width: 100%;
  height: 102px;
}

.liuxuejihua {
  text-align: left;
  margin: 0 0 0 20px;
  position: absolute;
  top: 0;
  font-size: 20px;
  color: #fff;
}

.liuxuejihua > div {
  height: 80px;
  margin-top: 40px;
}

.liuxuejihua span {
  padding: 16px 0;
  border-bottom: 2PX solid #fff;
  position: relative;
  top: 16px;
}


.frame ,.phone-frame{
  position: relative;
}
.phone-frame img{
  width:110PX;
  height: 80PX;
  position: absolute;
  top: 0;
  left: 10vw;
  z-index: 1000;
}
.frameone img {
  width: 25vw;
  height: 20vw;
  position: absolute;
  top: 0;
  left: 10vw;
  z-index: 1000;
}

.frametwo img {
  width: 25vw;
  height: 20vw;
  position: absolute;
  top: 0;
  left: 65vw;
  z-index: 1000;
}
.phone-frameone img{
  width: 150PX;
  height: 120PX;
  position: absolute;
  top: 0PX;
  left: 0;
  z-index: 1000;
}
.phone-frametwo img{
  width: 150PX;
  height: 120PX;
  position: absolute;
  top: 0PX;
  left: calc(100vw - 150PX);
  z-index: 1000;
}
.frameone-top {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.frameone-con {
  font-size: 16px;
  color: #333;
  margin: 12px 0 28px 0;
}

.frameone-bot {
  background: #FE992B;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  padding: 10px 0;
}


/* 移动端 */
.heightone{
  height: 1180PX;
  margin-top: 10PX;
}
.heighttwo{
  height: 1620PX;
}
.service-con-sm {
  width: 100%;
  position: absolute;
  top: 10PX;
}

.servicetop-sm {
  font-size: 20PX;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
  border-radius: 3PX;
  margin: 0 auto;
}

.servicebot-sm {
  width: 69PX;
  height: 6PX;
  background: #FE992B;
  border-radius: 3PX;
  margin: 0 auto;
}
.english-yellow-sm {
  color: #FE992B;
  font-size: 20PX;
  opacity: 0.3;
  font-weight: bold;
}
.all-service-sm{
   width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  >div{
    margin-top: 16PX;
  }
  img{
    width: 100%;
    height: 166PX;
  }
  .backgimage-service-two-sm img {
  width: 100%;
  height: 238PX;
}
}

 .backgimage-service-two-sm:hover,.backgimage-service-two:hover {
  
  cursor: pointer;
}
.companypng-sm {
  width: 100%;
  height: 362PX;
  margin-top: 16PX;
}
.main-service-bot-sm{
  padding: 30PX 0;
}
.all-service-company-sm{
  width: 100%;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  flex-wrap: wrap;
  margin-top: 20PX;
}
.all-service-company-sm>div,.all-service-company-sm>div img{
  width:110PX;
  height: 60PX;
  margin-bottom: 20PX;
}
</style>